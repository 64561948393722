<template>
  <asom-modal
    v-model="showTransactionSelection"
    :title="
      selectedSource == psc.psmManualRecord.VALUE
        ? 'Transaction Details'
        : 'Select Transactions'
    "
    :dismissible="false"
    size="lg"
  >
    <asom-alert v-if="error" :error-message="error" />
    <div v-if="!isLoading">
      <asom-client-table
        v-if="selectedSource != psc.psmManualRecord.VALUE"
        :columns="tableData.columns"
        :data="tableData.data"
        :filterable="false"
        :pagination="false"
      >
        <template v-slot:header_transactionNo>
          Transaction No
        </template>
        <template
          v-if="selectedSource == psc.preEncodedTicket.VALUE"
          v-slot:ticketValue="{ data }"
          >{{ formatCurrency(data) }}</template
        >
        <!-- <template
          v-if="selectedSource == psc.psmManualRecord.VALUE"
          v-slot:amount="{ data }"
          >{{ formatCurrency(data) }}</template
        > -->
        <template
          v-if="selectedSource == psc.preEncodedTicket.VALUE"
          v-slot:event="scopedSlots"
        >
          <span>{{ get(scopedSlots.data, "eventName", "") }}</span>
        </template>
        <template
          v-if="selectedSource == psc.cashFloatELog.VALUE"
          v-slot:header_select
        >
          <input type="checkbox" v-model="multiSelectAll" @change="onSelect" />
        </template>
        <template
          v-if="selectedSource == psc.cashFloatELog.VALUE"
          v-slot:select="scopedSlots"
        >
          <input
            class="mx-2"
            type="checkbox"
            v-model="scopedSlots.rowData.select"
            @change="onSelect"
          />
        </template>
        <template v-else v-slot:select="scopedSlots">
          <input
            type="radio"
            :value="scopedSlots.rowData"
            v-model="selectedTransaction"
          />
        </template>
        <template 
          v-slot:transactionType="scopedSlots" 
          v-if="selectedSource == psc.cashFloatELog.VALUE"
          >
          {{get(scopedSlots.rowData, "transactionType") === "Discard" ? "Used" : get(scopedSlots.rowData, "transactionType")}}
        </template>
        <template
          v-if="selectedSource == psc.coinFlush.VALUE"
          v-slot:retrievedAmount="{ data }"
          >{{ formatCurrency(data) }}</template
        >
      </asom-client-table>
      <div v-if="selectedSource == psc.psmManualRecord.VALUE" class="mt-5">
        <div
          class="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6 flex"
        >
          <div class="flex-shrink-0">
            <div
              class="m-2 mt-0 h-10 w-10 rounded-full bg-gray-300 flex items-center justify-center"
            >
              <asom-icon icon="credit-card" />
            </div>
          </div>
          <div class="flex-1 min-w-0">
            <div class="text-sm font-medium text-gray-500 truncate">
              Current balance in PSM
            </div>
            <div class="mt-1 text-3xl font-semibold text-gray-900">
              {{ formatCurrency(this.currentBalance) }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="text-center">
      <asom-icon icon="spinner" class="animate-spin" />
    </div>
    <div class="flex flex-row-reverse pt-4 gap-4">
      <asom-button
        @click="onSubmit"
        :disabled="disableConfirmation"
        :loading="isLoading"
        text="Confirm"
      />
      <asom-button
        @click="toggleModal(false)"
        text="Cancel"
        variant="secondary"
      />
    </div>
  </asom-modal>
</template>

<script>
import get from "lodash.get";
import filter from "lodash.filter";
import { mapGetters } from "vuex";
import {
  getPSCCashFloatMainPageData,
  getPSMMainPageData,
  getPreEncodedMainPageData,
  listCoinFlushForms,
} from "@/services/cashManagement.service";
import { psc } from "../../../constants/APIEnums/cashManagementEnums";
import { formatCurrency } from "../../../helpers/numbers";

export default {
  props: {
    selectedSource: {
      type: undefined,
    },
    modelValue: {
      type: Boolean,
    },
  },
  emits: ["update:modelValue", "selectedTransactions"],
  data() {
    return {
      error: null,
      disableConfirmation: false,
      isLoading: true,
      transactionsList: [],
      selectedTransactions: [],
      selectedTransaction: null,
      psc,
      currentBalance: 0,
    };
  },
  computed: {
    ...mapGetters({
      pscId: "cashManagement/stationCashAccounts/pscId",
      afcId: "cashManagement/stationCashAccounts/afcId",
    }),
    showTransactionSelection: {
      get() {
        return this.modelValue;
      },
      set(newValue) {
        this.$emit("update:modelValue", newValue);
      },
    },
    tableData() {
      if (this.selectedSource == psc.psmManualRecord.VALUE) {
        return {
          columns: ["transactionNo", "input", "amount"],
          data: this.transactionsList,
        };
      } else if (this.selectedSource == psc.preEncodedTicket.VALUE) {
        return {
          columns: [
            "select",
            "transactionNo",
            "event",
            "noOfTickets",
            "ticketValue",
          ],
          data: this.transactionsList.map((transaction) => ({
            select: false,
            ...transaction,
          })),
        };
      } else if (this.selectedSource == psc.cashFloatELog.VALUE) {
        return {
          columns: [
            "select",
            "transactionNo",
            "certisBagNo",
            "transactionType",
          ],
          data: this.transactionsList.map((transaction) => ({
            select: false,
            ...transaction,
          })),
        };
      } else if (this.selectedSource == psc.coinFlush.VALUE) {
        return {
          columns: ["select", "referenceNo"],
          data: this.transactionsList.map((transaction) => ({
            select: false,
            ...transaction,
          })),
        };
      } else
        return {
          columns: [],
          data: [],
        };
    },
    multiSelectAll: {
      get() {
        return this.transactionsList.every((transaction) => transaction.select);
      },
      set(newValue) {
        this.transactionsList = this.transactionsList.map((transaction) => ({
          ...transaction,
          select: newValue,
        }));
      },
    },
  },
  watch: {
    modelValue: function(newValue) {
      if (newValue) {
        this.loadTransactions();
        this.isLoading = true;
      }
    },
    transactionsList: function() {
      if (this.selectedSource == psc.psmManualRecord.VALUE) {
        this.selectedTransactions = this.transactionsList;
      }
    },
    selectedTransaction(newValue, oldValue) {
      if (newValue !== oldValue) {
        const transactions = [newValue];
        this.selectedTransactions = transactions;
      }
    },
  },
  methods: {
    formatCurrency,
    get,
    toggleModal(value) {
      this.$emit("update:modelValue", value);
      this.selectedTransactions = [];
      this.selectedTransaction = null;
    },
    onSelect() {
      const newValue = [];
      this.$nextTick(() => {
        this.tableData.data.forEach((transaction) => {
          if (transaction.select) newValue.push(transaction);
        });
        this.selectedTransactions = newValue;
      });
    },
    onSubmit() {
      this.$emit("selectedTransactions", this.selectedTransactions);
      this.toggleModal(false);
    },
    loadTransactions() {
      if (this.selectedSource == psc.psmManualRecord.VALUE) {
        this.getPSMTransactions();
      } else if (this.selectedSource == psc.preEncodedTicket.VALUE) {
        this.getPreEncodedTransactions();
      } else if (this.selectedSource == psc.cashFloatELog.VALUE) {
        this.getCashFloatTransactions();
      } else if (this.selectedSource == psc.coinFlush.VALUE) {
        this.getCoinFlushs();
      }
    },
    async getCashFloatTransactions() {
      const result = await getPSCCashFloatMainPageData({
        pSCId: this.pscId,
      });
      if (result.success) {
        this.transactionsList = filter(
          get(result.payload.data, "transactions", []),
          {
            addedToCDF: false,
            transactionTypeEnum:
              psc.cashFloatELog.CERTIS_BAG_TRANSACTION_TYPE.DISCARD.VALUE,
            isVoided: false,
          }
        );
      } else this.error = result.payload;
      this.isLoading = false;
    },
    async getPSMTransactions() {
      const result = await getPSMMainPageData({
        pSCId: this.pscId,
      });
      if (result.success) {
        this.transactionsList = filter(
          get(result.payload.data, "transactions", []),
          {
            addedToCDF: false,
            isDeleted: false,
          }
        );
        this.currentBalance = get(result.payload.data, "currentBalance", 0);
      } else this.error = result.payload;
      this.isLoading = false;
    },
    async getPreEncodedTransactions() {
      const result = await getPreEncodedMainPageData({
        pSCId: this.pscId,
      });
      if (result.success) {
        this.transactionsList = filter(
          get(result.payload.data, "transactions", []),
          {
            addedToCDF: false,
            transactionTypeEnum:
              psc.preEncodedTicket.TRANSACTION_TYPE.ISSUE.VALUE,
            isDeleted: false,
          }
        );
      } else this.error = result.payload;
      this.isLoading = false;
    },
    async getCoinFlushs() {
      const result = await listCoinFlushForms(this.afcId);
      if (result.success) {
        this.transactionsList = filter(get(result.payload, "list", []), {
          addedToCDF: false,
          isVoided: false,
        });
      } else this.error = result.payload;
      this.isLoading = false;
    },
  },
};
</script>
